import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import '@/assets/css/estilos.css'
import router from './router'
import './registerServiceWorker'
import EventBus from '@/plugins/bus'
import('primevue/resources/themes/mdc-dark-indigo/theme.css')

import('primevue/resources/primevue.min.css')
import('primeicons/primeicons.css')
import VUppercase from 'v-uppercase'
Vue.use(VUppercase)
Vue.config.productionTip = false
new Vue({
  vuetify,
  router,
  render: h => h(App),
}).$mount('#app')
Vue.use(EventBus)
