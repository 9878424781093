<template>
  <v-app>
    <div style="height: 100vh" align="center" justify-xl="center" class="fondoLogin">

      <v-snackbar
        top="0%"
        :color="alertaModalsColor"
        v-model="alertaModals"
        :multi-line="true"
        :timeout="5000"
        centered
        outlined
        >
        {{ textAlertModals }}
        <template   >
          <v-btn 
          :color="alertaModalsColor"
            text  
            elevation="24"
            @click="alertaModals = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>

      <v-bottom-sheet v-model="sheet" persistent>
        <template v-slot:activator="{ on }">
          <v-card class="d-inline-block mt-50 margenLogin">
            <v-container>
              <v-row>
                <v-col>
                  <div>
                    <img class="logo" src="../assets/imgs/img_logo.png" />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="px-5">
                  <v-form ref="form" lazy-validation>
                    <v-icon>mdi-account_circle</v-icon>
                    <v-text-field
                      @keypress="validar"
                      @mousemove="validar"
                      prepend-icon="mdi-account-circle"
                      v-model="user"
                      outlined
                      label="Usuario"
                      required
                    ></v-text-field>

                    <v-text-field
                      @keypress="validar"
                      @mousemove="validar"
                      @keypress.enter="login"
                      v-model="pass"
                      prepend-icon="mdi-lock"
                      label="Contraseña"
                      required
                      outlined
                      type="password"
                    ></v-text-field> 
                    <br />
                    <v-btn
                      :disabled="!valid||loading"
                      color="success"
                      class="mr-4"
                      @click="login"
                      :loading="loading"
                       v-on="on"
                    >Acceder</v-btn>
                  </v-form>
                  <v-btn
                      light
                      fab
                      small
                      class="mr-4 mt-3"
                      @click="iniciarConGoogle"
                      :loading="loading"
                    ><img height="30px" src="../../public/img/icons/1534129544.svg"></v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-alert prominent type="danger" class="alertLogin" v-if="aviso" dismissible>
                  <v-row align="center">
                    <v-col class="grow">{{this.error}}</v-col>
                  </v-row>
                </v-alert>
              </v-row>
            </v-container>
          </v-card>
        </template>

        <v-sheet class="text-center" height="200px">
          <v-btn class="mt-6" dark color="indigo" @click="sheet = !sheet">Aceptar</v-btn>
          <div class="py-3">
            Para recuperar tu contraseña será necesario mandar un correo a 
            <a
              href="mailto:laboratorio.digital@salud-digna.org"
              target="_blank"
            >Laboratorio Digital</a>
          </div>
        </v-sheet>
      </v-bottom-sheet>
      <v-footer absolute bottom class="footerLogin">
        <b>v{{version}}</b>
        <Offline/>
      </v-footer>
    </div>
  </v-app>
</template>
<style lang="scss">
.logo{
  width: 100%;
  padding: 2rem;
}
.footerLogin{
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
<script>
import servicios from '@/service/login'
import {version} from '../../package'
//import tema from '../service/tema'
//import idb from '@/service/idb';
import Offline from "../components/Conectivity";
import { signin } from '../firebase/auth_google.js'

  export default {
    name: 'LogIn',

    data: () => ({
      valid: false,
      show1:false,
      aviso:false,
      avisoMSJ:null,
      user: '',
      pass: '',
      loading:false,
      version:version,
      sheet:false,
      passRules: [
        v => !!v || 'Contraseña es requerida',
        v => v.length >= 4 || 'Favor de ingresar una contraseña valida.',
      ],
      email: '',
      emailRules: [
        v => !!v || 'Correo electrónico necesario',
        v => /.+@.+/.test(v) || 'Favor de introducir un correo electrónico valido.',
      ],
      alertaModalsColor: '',
      alertaModals: false,
      textAlertModals: ''
    }),
    components: {
      Offline
    },
    methods:{
      validar() {
        if (
          this.user !== null &&
          this.pass !== null &&
          this.user !== "" &&
          this.pass !== ""
        ) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      },
      iniciarConGoogle(){
        signin().then( res=> {
          this.login(res)
        })
      },
      async login(googleSign){
        let email, pass, funcion
        this.loading=true

        if(googleSign.providerId) {
          email = googleSign.email
          pass = googleSign.uid 
          funcion = 'loginGoogle'
        }
        else{
          if(this.user.indexOf('@')==-1){
            this.user+='@salud-digna.org'
          }
          email = this.user.toLowerCase()
          pass = this.pass
          funcion = 'login'
        }
        servicios[funcion](email, pass).then(async res =>{
          if(res){
            let datosUser = {
                id: res.user.id,
                nombre: res.user.nombre,
                email: res.user.email,
                rol_id: res.user.rol_id,
                estatus: res.user.estatus,
                oculto: res.user.respuestas==1 ? false :true ,
                lastconnection: res.user.lastconnection,
                createdAt: res.user.createdAt,
                updatedAt: res.user.updatedAt,
                token:  res.accesToken,
                grupo_id: res.user.grupo_id
              }
              sessionStorage.clear()
              sessionStorage.setItem('sesion', JSON.stringify(datosUser))
            this.$router.push('/dashboard')
            this.$gtag.event('login',{
              'event_category': 'login exitoso',
              'event_label': res.data.data.username
            })

          }else{
            
            this.loading=false
          }
        }).catch(err => {
          // if (err.status>=400){
          //   this.aviso=true
          //   this.avisoMSJ=err.data
          // }
          if(err.data.msj){
            this.mensajeAlertaModals('error', err.data.msj)
          }
          // this.$gtag.event('errores',{
          //   'event_category': 'error',
          //   'event_label': err.data,
          //   'value': err.status
          // })
          this.loading=false
        })
      },
      mensajeAlertaModals(tipo,texto){
        this.alertaModalsColor =tipo
        this.alertaModals =true
        this.textAlertModals =texto
      },
    }
  }
</script>
