import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import configAuth from "../firebase/firebase"

const provider = new GoogleAuthProvider();
const auth = configAuth;

export const signin = () => signInWithPopup(auth, provider.setCustomParameters({prompt:'select_account'}))
    .then((result) => {
        return result.user
    }).catch((error) => {
        console.log(error)
    });