import api from './api'
let service = {}

service.login = function(user, pass){
    return api.post('/login', {user,pass}).then(res => res.data).catch(r=>r)
}

service.loginGoogle = function(user, uid){
    return api.post('/login', {user, uid}).then(res => res.data)
}

service.subirDocumento = function(data,nombre_generado){ 
    return api.post(`/uploadmultimedia/0/${nombre_generado}`,data).then(res => res.data).catch(r=>r)
}

export default service