import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Inicio from '../components/pages/Inicio.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    children:[
      {
        path: '/dashboard',
        name: 'Inicio',
        component: () => import('../components/pages/Inicio.vue'),
        meta: { title: 'Inicio - Salud Digna' }
      },
      {
        path: '/dashboard/hsm',
        name: 'hsms',
        component: () => import('../components/pages/Hsm.vue'),
        meta: { title: 'HSMs - Salud Digna' }
      },{
        path: '/dashboard/pendientes',
        name: 'pendientes',
        component: () => import('../components/pages/Pendientes.vue'),
        meta: { title: 'Envios Pendientes - Salud Digna' }
      },{
        path: '/dashboard/envios',
        name: 'envios',
        component: () => import('../components/pages/Flujo.vue'),
        meta: { title: 'Envios - Salud Digna' }
      },{
        path: '/dashboard/generador',
        name: 'generador',
        component: () => import('../components/pages/QRs.vue'),
        meta: { title: 'Generador - Salud Digna' }
      },
      {
        path: '/dashboard/campanas',
        name: 'campanas',
        component: () => import('../components/pages/Campanas.vue'),
        meta: { title: 'Campañas - Salud Digna' }
      },
      {
        path: '/dashboard/documentos',
        name: 'documentos',
        component: () => import('../components/pages/Documentos.vue'),
        meta: { title: 'Documentos - Salud Digna' }
      },
      {
        path: '/dashboard/correos',
        name: 'correos',
        component: () => import('../components/pages/Correos.vue'),
        meta: { title: 'Correos - Salud Digna' }
      },
      {
        path: '/dashboard/links',
        name: 'links',
        component: () => import('../components/pages/Links.vue'),
        meta: { title: 'Acortador - Salud Digna' }
      },
      {
        path: '/dashboard/blacklists',
        name: 'blacklists',
        component: () => import('../components/pages/blacklist.vue'),
        meta: { title: 'Blacklists - Salud Digna' }
      },
      {
        path: '/dashboard/reportes',
        name: 'Reportes',
        component: () => import('../components/pages/Reportes.vue'),
        meta: { title: 'Reportes - Salud Digna' },
        children:[
          {
            path: '/dashboard/reportes/general',
            name: 'rGeneral',
            component: () => import('../components/pages/Reportes.vue'),
            meta: { title: 'Reporte General - SD' },
          },{
            path: '/dashboard/reportes/gastos',
            name: 'rGastos',
            component: () => import('../components/pages/Reportes.vue'),
            meta: { title: 'Reporte de Gastos - SD' },
          },{
            path: '/dashboard/reportes/cortes',
            name: 'rCortes',
            component: () => import('../components/pages/Reportes.vue'),
            meta: { title: 'Reporte de Cortes - SD' },
          },

        ]
      },
    ]
  },
]

const router = new VueRouter({
  routes,
  // mode: 'history',
  // base: process.env.BASE_URL,
})
const DEFAULT_TITLE = 'Plataforma de Envios';
router.afterEach((to) => {
    document.title = to.meta.title || DEFAULT_TITLE;
});
export default router
